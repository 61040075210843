import { defineStore } from "pinia";
import { ref } from "vue";

export const useTimingStore = defineStore("timing", () => {
  // State
  const timeOffset = ref<number>(0);
  const lastCheckAt = ref<number>(null);

  // Actions
  const checkTime = async () => {
    const offsets = [];
    for (let i = 0; i < 2; i++) { // repeat multiple times for a more certain result
        const origin = Date.now();
        const response = await fetch("/time.json", {
          headers: { "Content-Type": "application/json" }
        });
        const returned = Date.now();
        const received = (await response.json()).time;
        const sending = received-origin;
        const receiving = returned-received;
        offsets.push((receiving - sending) / 2);

        //wait for a bit, to avoid spamming the server
        await new Promise(r => setTimeout(r, 200));
    }

    timeOffset.value = offsets.reduce((sum, offset) => sum + offset) / offsets.length; // take mean value
    lastCheckAt.value = Date.now();
    // It is assumed that the time it takes to send is about the same as the time it takes to receive,
    // which means the difference between the sending and receiving says something about the offset.
    // If it takes 10ms for a message to transmit, and the sending time is 15ms, while receiving is 5ms,
    // it means:
    // ( 0->10) c  -?-> s ( 5->15)  origin is 0, client asks for server time, it takes 10ms to reach the server
    // (10->20) c <-15- s (15->25)  received is 15, the server responds with its time, the response takes 10ms to return
    //                              returned is 20
    //                              sending is 15
    //                              receiving is 5
    //                              offset is -5
    //
    // therefore the client is 5ms behind the server
  }

  return {
    timeOffset,
    lastCheckAt,
    checkTime,
  }
});
